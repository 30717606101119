export interface ICookiesText {
  title: string;
  description: string;
  descriptionFuncionality: string;
  cookiesFuncionality: string;
  descriptionAnalytics: string;
  cookiesAnalytics: string;
}

export async function getCookiesText(): Promise<ICookiesText> {
  let cookiesText: ICookiesText = {
    title: "",
    description: "",
    descriptionFuncionality: "",
    cookiesFuncionality: "",
    descriptionAnalytics: "",
    cookiesAnalytics: "",
  };

  return new Promise((resolve, reject) => {
    fetch(
      "https://space-editor.metrovacesa.com/items/cookies/1?fields=translations.*&deep[translations][_filter][languages_code][_eq]=es-ES"
    )
      .then((value: Response) => {
        value
          .json()
          .then((value: any) => {
            if (value["data"]["translations"].length > 0) {
              cookiesText.title =
                value["data"]["translations"][0][
                  "titulo_informacion_acerca_de_las_cookies"
                ];
              cookiesText.description =
                value["data"]["translations"][0]["descripcion_cookies"];
              cookiesText.descriptionFuncionality =
                value["data"]["translations"][0][
                  "descripcion_cookies_funcionales"
                ];
              cookiesText.cookiesFuncionality =
                value["data"]["translations"][0]["cookies_funcionales"];
              cookiesText.descriptionAnalytics =
                value["data"]["translations"][0][
                  "descripcion_cookies_analiticas"
                ];
              cookiesText.cookiesAnalytics =
                value["data"]["translations"][0]["cookies_analiticas"];
              resolve(cookiesText);
            } else {
              reject(cookiesText);
            }
          })
          .catch();
      })
      .catch(() => {
        reject(cookiesText);
      });
  });

  return cookiesText;
}
