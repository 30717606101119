import { SyntheticEvent, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import Switch from "react-switch";
import { ICookiesText, getCookiesText } from "./backends";
import parse from "html-react-parser";

interface ICookiesAdvanced {
  callback: (value: boolean) => void;
  cookiesText: ICookiesText | undefined;
}

const CookiesAdvancedOptions: React.FC<ICookiesAdvanced> = ({
  callback,
  cookiesText,
}) => {
  const [viewDetailsFuncionals, setViewDetailsFuncionals] =
    useState<boolean>(false);

  const [viewDetailsAnalytics, setViewDetailsAnalytics] =
    useState<boolean>(false);

  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (
      Cookies.get("cookies-consent") === undefined &&
      Cookies.get("analytics-imascono") !== undefined
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, []);

  const handleChange = (
    checkSwitch: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string
  ) => {
    setChecked(!checked);
    callback(checkSwitch);
  };

  return (
    <div className="flex flex-col my-4">
      <details
        id="detailsFuncionals"
        onToggle={() => {
          setViewDetailsFuncionals(!viewDetailsFuncionals);
        }}
      >
        <summary>
          <div className="flex items-center justify-between p-2 my-1 rounded-lg bg-blueLightCookies cursor-pointer">
            <div className="flex items-center justify-center">
              <span>
                {!viewDetailsFuncionals && (
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 320 512"
                    className="mr-2"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                  </svg>
                )}
                {viewDetailsFuncionals && (
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 448 512"
                    className="mr-2"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                  </svg>
                )}
              </span>
              <span>Funcionales</span>
            </div>
            <div className="flex items-center">
              {" "}
              <span>Siempre habilitadas</span>
            </div>
          </div>
        </summary>
        <div className="p-2 text-xs necesary-info-text-enter-done">
          <p>{cookiesText?.descriptionFuncionality}</p>
          {parse(cookiesText!.cookiesFuncionality)}
        </div>
      </details>

      <details
        id="detailsAnalytics"
        onToggle={() => {
          setViewDetailsAnalytics(!viewDetailsAnalytics);
        }}
      >
        <summary>
          <div className="flex items-center justify-between p-2 my-1 rounded-lg bg-blueLightCookies cursor-pointer">
            <div className="flex items-center justify-center">
              <span>
                {!viewDetailsAnalytics && (
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 320 512"
                    className="mr-2"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                  </svg>
                )}
                {viewDetailsAnalytics && (
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 448 512"
                    className="mr-2"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                  </svg>
                )}
              </span>
              <span>Analíticas</span>
            </div>
            <div className="flex items-center">
              {!checked && <span className="mr-2">Deshabilitadas</span>}
              {checked && <span className="mr-2">Habilitadas</span>}

              <div className="checkBox">
                <Switch
                  id="normal-switch"
                  onChange={handleChange}
                  checked={checked}
                />
              </div>
            </div>
          </div>
        </summary>
        <div className="p-2 text-xs necesary-info-text-enter-done">
          <p className="my-2">{cookiesText?.descriptionAnalytics}</p>
          {parse(cookiesText!.cookiesAnalytics)}
        </div>
      </details>
    </div>
  );
};

export default CookiesAdvancedOptions;
