import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    returnNull: false,
    supportedLngs: ["en-US", "es-ES", "pt-PT"],
    resources: {
      "es-ES": {
        translation: {
          BACK: "Atrás",
          ENTER: "Entrar",
          PRIVATE_AREA: "Área privada",
          HEADER_NAME: "Ponte un nombre",
          NAME: "Nombre",
          ENTER_CODE: "Introduce el código de acceso",
          IDIOMA_CASTELLANO: "CASTELLANO",
          IDIOMA_INGLES: "INGLÉS",
          IDIOMA_PORTUGUES: "PORTUGUÉS",
          MESSAGE_NOT_ENTER_1: "En estos momentos el aforo está completo.",
          MESSAGE_NOT_ENTER_2:
            "Por favor, vuelve a intentarlo dentro de un rato.",
          CODE: "Código",
          WELCOME_LANDING: "BIENVENID@S A METROVACESA",
        },
      },
      "en-US": {
        translation: {
          BACK: "Back",
          ENTER: "Start",
          PRIVATE_AREA: "Private area",
          HEADER_NAME: "Name yourself",
          NAME: "Name",
          ENTER_CODE: "Enter the access code",
          IDIOMA_CASTELLANO: "SPANISH",
          IDIOMA_INGLES: "ENGLISH",
          IDIOMA_PORTUGUES: "PORTUGUESE",
          MESSAGE_NOT_ENTER_1: "We are currently at full capacity.",
          MESSAGE_NOT_ENTER_2: "Please try again in a little while.",
          CODE: "Code",
          WELCOME_LANDING: "WELCOME TO THE 2023 GLOBAL WIND DAY PARTY",
        },
      },
      "pt-PT": {
        translation: {
          BACK: "Voltar",
          ENTER: "Início",
          PRIVATE_AREA: "Área privada",
          HEADER_NAME: "Dar um nome a si próprio",
          NAME: "nome",
          CODE: "Código",
          ENTER_CODE: "Introduza o seu código de acesso",
          IDIOMA_CASTELLANO: "CASTELHANO",
          IDIOMA_INGLES: "INGLÊS",
          IDIOMA_PORTUGUES: "PORTUGUÊS",
          MESSAGE_NOT_ENTER_1: "No momento, a capacidade está cheia.",
          MESSAGE_NOT_ENTER_2: "Tente novamente daqui a pouco.",
          WELCOME_LANDING: "BEM-VINDO À FESTA DO DIA MUNDIAL DO VENTO DE 2023",
        },
      },
    },
    // lng: "es", // if you're using a language detector, do not define the lng option
    fallbackLng: "es-ES",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
