import { SyntheticEvent, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import CookiesBanner from "./CookiesBanner";
import { sendMatomoEvent } from "./utils/matomoUtils";

const Home = () => {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const URLPATH = "./virtualspace";

  function enterSpace(event: SyntheticEvent) {
    try {
      window.sessionStorage.setItem("access", "true");
      sendMatomoEvent("Button", "Click");
      event.preventDefault();
      window.open(URLPATH, "_self");
    } catch (err) {
      enqueueSnackbar("Ha ocurrido un error", {
        variant: "error",
      });
    }
  }

  // useEffect(() => {
  //   var script = document.createElement("script");
  //   script.type = "text/javascript";
  //   let userId = Cookies.get("cookie-consent");
  //   script.innerHTML = `
  //                   var _paq = window._paq = window._paq || [];
  //                   /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  //                   ${
  //                     Cookies.get("analytics-imascono") !== undefined
  //                       ? "_paq.push(['setConsentGiven']);"
  //                       : "_paq.push(['forgetConsentGiven']);"
  //                   }
  //                   _paq.push(['setDocumentTitle', "Espacio principal"]);
  //                   _paq.push(['setCustomUrl', "Espacio principal"]);
  //                   _paq.push(['setUserId', "${userId}"]);
  //                   _paq.push(['trackPageView']);
  //                   _paq.push(['enableLinkTracking']);
  //                   (function() {
  //                       var u="https://matomo.imascono.com/";
  //                       _paq.push(['setTrackerUrl', u+'matomo.php']);
  //                       _paq.push(['setSiteId', '122']);
  //                       var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  //                       g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  //                   })();`;
  //   document.head.appendChild(script);
  // }, []);

  return (
    <>
      <div
        className="absolute items-center h-screen w-screen gap-8 sm:gap-14 bg-cover bg-center bg-no-repeat blur-sm"
        style={{
          backgroundImage: "url('./images/metrovacesaBG.jpg')",
        }}
      >
        <div className="w-full h-full bg-white/50"></div>
      </div>
      <div className="flex justify-center w-full">
        <div className="absolute flex w-full md:w-1/3 h-full max-h-full overflow-auto flex-col justify-center items-center text-[#0F0D6A]">
          <img
            className="w-[60%] md:w-[70%] h-auto md:mt-10"
            src="./images/mvcLogo.png"
          ></img>
          <div className="font-['Inter'] text-center text-2xl font-bold px-6 mt-5 md:mt-12">
            ¡Bienvenido a Metrovacesa Space: donde la innovación y el hogar de
            tus sueños se unen en un solo lugar!
          </div>
          <div className="font-['Inter'] text-center text-lg font-normal px-6 mt-8">
            Nuestro equipo te acompañará en un viaje emocionante a través de
            nuestros proyectos, valores y visión de futuro. Entra y explora
            nuestro universo, comienza eligiendo tu avatar.
          </div>
          <button
            onClick={enterSpace}
            className="mt-10 md:mt-[5%] w-52 h-10 bg-black rounded-xl text-white font-['Inter'] text-base font-normal cursor-pointer transition duration-200 transform hover:scale-110 z-50"
            style={{
              background: "linear-gradient(160deg, #1A40A9 0%, #2156E7 100%)",
            }}
          >
            Entrar
          </button>
          {/* <div className="font-['Inter'] text-center text-black text-xs font-light px-8 md:px-24 mt-4">
            <input
              onClick={() => {
                setCheckbox(!checkbox);
              }}
              type="checkbox"
              id="cookies"
              name="cookies"
              value="textCookie"
            ></input>
            <label className="ml-1" htmlFor="cookies">
              Al seleccionar "Entrar", acepto las Condiciones del servicio y la
              Política de privacidad.
            </label>
          </div> */}
        </div>
      </div>

      <div className="absolute w-full h-8 bg-[#F5F5F5] bottom-0 left-0 flex flex-row justify-center items-center text-center font-['Inter'] text-[0.55rem] md:text-xs font-normal text-[#4440FF]">
        <div className="cursor-pointer">
          <a href="https://metrovacesa.com/aviso-legal" target="_blank">
            Aviso legal -{" "}
          </a>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/cookies");
          }}
        >
          &nbsp;Política de cookies -
        </div>
        <div className="cursor-pointer">
          <a href="https://metrovacesa.com/politica-privacidad" target="_blank">
            &nbsp;Política de privacidad -
          </a>
        </div>
        <div
          id="cookiesConfiguration"
          className="cursor-pointer"
          onClick={() => {
            Cookies.remove("cookie-consent", { path: "/" });
            window.location.reload();
          }}
        >
          &nbsp;Configuración de cookies
        </div>
      </div>

      <CookiesBanner />
    </>
  );
};
export default Home;
