import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CookiesPolitic = () => {
  let navigate = useNavigate();

  return (
    <div className="absolute inset-0 bg-white">
      <div className="w-full h-full select-none ">
        <div>
          <div className="flex p-4 bg-blueMetrovacesa justify-center relative">
            <div
              className="absolute left-0 flex items-center font-bold text-white px-2 top-1/2 -translate-y-1/2 cursor-pointer"
              onClick={() => {
                navigate(-1);
              }}
            >
              <svg
                className="rotate-180 mr-2"
                width="25"
                height="16"
                viewBox="0 0 25 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 7.125C0.447715 7.125 5.68981e-08 7.57272 0 8.125C-5.68981e-08 8.67728 0.447715 9.125 1 9.125L1 7.125ZM24.3922 8.83211C24.7828 8.44158 24.7828 7.80842 24.3922 7.41789L18.0283 1.05393C17.6377 0.663409 17.0046 0.663409 16.6141 1.05393C16.2235 1.44446 16.2235 2.07762 16.6141 2.46815L22.2709 8.125L16.6141 13.7819C16.2235 14.1724 16.2235 14.8055 16.6141 15.1961C17.0046 15.5866 17.6377 15.5866 18.0283 15.1961L24.3922 8.83211ZM1 9.125L23.6851 9.125L23.6851 7.125L1 7.125L1 9.125Z"
                  fill="#ffffff"
                ></path>
              </svg>
              <span className="uppercase">ATRÁS</span>
            </div>
          </div>
          <div className="bg-white flex flex-col gap-4">
            <div className="flex font-['Inter'] items-center bg-[#F8F8F9] w-full h-[15rem] text-6xl md:text-7xl font-bold">
              <div className="flex mx-6 md:mx-56">Política de cookies</div>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-bold text-2xl text-[#1e48bc]">
              1. ANTECEDENTES
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              De conformidad con la normativa española que regula el uso de
              cookies, le informamos sobre las cookies utilizadas en este sitio
              web.
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-bold text-2xl text-[#1e48bc] mt-2">
              2.- ¿QUE SON LAS COOKIES?
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              Una cookie es un fichero que se descarga en el ordenador del
              usuario al acceder a determinadas páginas web y que almacenan y
              recuperan información cuando navega. Las cookies permiten, entre
              otras cosas, reconocerte como usuario, obtener información sobre
              los hábitos de navegación del usuario, o personalizar la forma en
              que se muestra el contenido. Los usos de las cookies realizado por
              esta página web, se describen en esta Política de Cookies.
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-bold text-2xl text-[#1e48bc] mt-2">
              3.- TIPOS DE COOKIES
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 font-normal text-base flex-col gap-2">
              <div className="whitespace-pre-wrap">
                Las cookies se pueden clasificar de la siguiente forma:
              </div>
              <div className="underline">
                <strong>Cookies según la entidad que las gestiona:</strong>
              </div>
              <div className="whitespace-pre-wrap">
                <strong>1.- Cookies propias:</strong> Son aquéllas que se envían
                al equipo terminal del usuario desde un equipo o dominio
                gestionado por el propio editor y desde el que se presta el
                servicio solicitado por el usuario.
              </div>
              <div className="whitespace-pre-wrap">
                <strong>2.- Cookies de terceros:</strong> Son aquéllas que se
                envían al equipo terminal del usuario desde un equipo o dominio
                que no es gestionado por el editor, sino por otra entidad que
                trata los datos obtenidos través de las cookies.
              </div>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 font-normal text-base flex-col gap-2">
              <div className="underline">
                <strong>Cookies según la finalidad:</strong>
              </div>
              <div className="whitespace-pre-wrap">
                <strong>1.- Cookies funcionales o técnicas: </strong> son
                aquellas que permiten al usuario la navegación a través de una
                página web, plataforma o aplicación y la utilización de las
                diferentes opciones o servicios que en ella existan, incluyendo
                aquellas que el editor utiliza para permitir la gestión y
                operativa de la página web y habilitar sus funciones y
                servicios.
              </div>
              <div className="whitespace-pre-wrap">
                <strong>2.- Cookies de preferencias o personalización: </strong>
                son aquellas que permiten recordar información para que el
                usuario acceda al servicio con determinadas características que
                pueden diferencias su experiencia de la de otros usuarios.
              </div>
              <div className="whitespace-pre-wrap">
                <strong>3.- Cookies de análisis o medición: </strong>
                Son aquéllas que permiten al responsable de las mismas el
                seguimiento y análisis del comportamiento de los usuarios de los
                sitios web a los que están vinculadas.
              </div>
              <div className="whitespace-pre-wrap">
                <strong>4.- Cookies de publicidad comportamental: </strong>
                Son aquéllas que almacenan información del comportamiento de los
                usuarios obtenida a través de la observación continuada de sus
                hábitos de navegación, lo que permite desarrollar un perfil
                específico para mostrar publicidad en función del mismo.
              </div>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 font-normal text-base flex-col gap-2">
              <div className="underline">
                <strong>
                  Cookies según el plazo de tiempo que permanecen activadas:
                </strong>
              </div>
              <div className="whitespace-pre-wrap">
                <strong>1.- Cookies de sesión: </strong> son aquellas diseñadas
                para recabar y almacenar datos mientras el usuario accede a una
                página web.
              </div>
              <div className="whitespace-pre-wrap">
                <strong>2.- Cookies persistentes: </strong> son aquellas en las
                que los datos siguen almacenados en el terminal y pueden ser
                accedidos y tratados durante un periodo definido por el
                responsable de la cookie, y que puede ir de unos minutos a
                varios años.
              </div>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-bold text-2xl text-[#1e48bc] mt-2">
              4.- TIPOS DE COOKIES UTILIZADAS POR ESTE SITIO WEB
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              Nuestra página web utiliza las siguientes cookies:
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-[0.65rem] md:text-base">
              <table className="w-[80%] border-[1px] border-black justify-center items-center">
                <tr>
                  <th className="border-[1px] border-black bg-[#F8F8F9]">
                    Nombre
                  </th>
                  <th className="border-[1px] border-black bg-[#F8F8F9]">
                    Titularidad
                  </th>
                  <th className="border-[1px] border-black bg-[#F8F8F9]">
                    Tipo
                  </th>
                  <th className="border-[1px] border-black bg-[#F8F8F9]">
                    Caducidad
                  </th>
                  <th className="border-[1px] border-black bg-[#F8F8F9]">
                    Finalidad
                  </th>
                </tr>
                <tr>
                  <td className="border-[1px] border-black justify-center items-center">
                    cookie_consent
                  </td>
                  <td className="border-[1px] border-black">Imascono</td>
                  <td className="border-[1px] border-black">Funcional</td>
                  <td className="border-[1px] border-black">1 año</td>
                  <td className="border-[1px] border-black">
                    Esta cookie se utiliza para saber si el usuario ha guardado
                    su configuración de cookies y mostrarle el banner de
                    aceptación.
                  </td>
                </tr>
                <tr>
                  <td className="border-[1px] border-black">_pk_id</td>
                  <td className="border-[1px] border-black">Imascono</td>
                  <td className="border-[1px] border-black">De análisis</td>
                  <td className="border-[1px] border-black">13 meses</td>
                  <td className="border-[1px] border-black">
                    Esta cookie se utiliza para almacenar algunos detalles sobre
                    el usuario, como la identificación única del visitante.
                  </td>
                </tr>
                <tr>
                  <td className="border-[1px] border-black">_pk_ses</td>
                  <td className="border-[1px] border-black">Imascono</td>
                  <td className="border-[1px] border-black">De análisis</td>
                  <td className="border-[1px] border-black">30 minutos</td>
                  <td className="border-[1px] border-black">
                    Se usa para almacenar temporalmente los datos de la visita.
                  </td>
                </tr>
                <tr>
                  <td className="border-[1px] border-black">
                    analytics-imascono
                  </td>
                  <td className="border-[1px] border-black">Imascono</td>
                  <td className="border-[1px] border-black">De análisis</td>
                  <td className="border-[1px] border-black">1 año</td>
                  <td className="border-[1px] border-black">
                    Se utiliza para recordar que el usuario ha dado su
                    consentimiento para el almacenamiento de los datos
                    registrados en las analíticas de Imascono.
                  </td>
                </tr>
              </table>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              En caso de aceptar las cookies de terceros, estás deberán ser
              eliminadas desde las opciones del navegador, tal y como se
              describe en el punto 5 de esta política o desde el sistema
              ofrecido por el tercero.
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-bold text-2xl text-[#1e48bc] mt-2">
              5.- REVOCACIÓN Y ELIMINACIÓN DE COOKIES
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              Cuando acceda por primera vez a nuestra web podrá aceptar o
              rechazar el uso de todas nuestras cookies o si lo prefiere, podrá
              acceder a la configuración y aceptar las cookies atendiendo al
              tipo de las mismas, exceptuando las cookies técnicas, las cuales
              están exceptuadas de necesitar consentimiento del usuario. Podrá
              cambiar sus preferencias accediendo a la configuración de su
              navegador para restringir, bloquear o borrar las cookies,
              siguiendo uno de los siguientes procedimientos, que depende del
              navegador que utilice:
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              <strong>Google Chrome</strong>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              <a
                className="text-[#1e48bc] underline"
                href="https://support.google.com/chrome/answer/95647?hl=es"
                target="_blank"
              >
                https://support.google.com/chrome/answer/95647?hl=es
              </a>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              <strong>Microsoft Internet Explorer</strong>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              <a
                className="text-[#1e48bc] underline"
                href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                target="_blank"
              >
                https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d
              </a>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              <strong>Firefox</strong>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              <a
                className="text-[#1e48bc] underline"
                href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es"
                target="_blank"
              >
                https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es
              </a>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              <strong>Safari , iPad e iPhone</strong>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              <a
                className="text-[#1e48bc] underline"
                href="https://support.apple.com/es-es/HT201265"
                target="_blank"
              >
                https://support.apple.com/es-es/HT201265
              </a>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              <strong>Opera</strong>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              <a
                className="text-[#1e48bc] underline"
                href="https://help.opera.com/en/latest/web-preferences/#cookies"
                target="_blank"
              >
                https://help.opera.com/en/latest/web-preferences/#cookies
              </a>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-bold text-2xl text-[#1e48bc] mt-2">
              6.- TERCEROS PRESTADORES DE SERVICIOS
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              En concreto los terceros prestadores de servicios con los que
              hemos contratado algún servicio para el que es necesario la
              utilización de cookies son:
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              <table className="w-[80%] border-[1px] border-black justify-center items-center">
                <tr>
                  <th className="border-[1px] border-black bg-[#F8F8F9]">
                    Proveedor
                  </th>
                  <th className="border-[1px] border-black bg-[#F8F8F9]">
                    Finalidad del Servicio y de la Cookie
                  </th>
                  <th className="border-[1px] border-black bg-[#F8F8F9]">
                    Información del Proveedor
                  </th>
                </tr>
                <tr>
                  <td className="border-[1px] border-black justify-center items-center">
                    Imascono
                  </td>
                  <td className="border-[1px] border-black">
                    Analizar la actividad agregada de los usuarios del espacio
                    virtual para la mejora del mismo
                  </td>
                  <td className="border-[1px] border-black">
                    Imascono Art S.L., gdpr@imascono.com
                  </td>
                </tr>
              </table>
            </div>
            <div className="flex font-['Inter'] mx-6 md:mx-56 items-center font-normal text-base">
              En caso de aceptar las cookies de terceros, estás deberán ser
              eliminadas desde las opciones del navegador, tal y como se
              describe en el punto 5 de esta política. <br></br>No se realizan
              transferencias internacionales de datos a terceros países.
            </div>

            {/* <h1 className="text-cookiesBlue uppercase text-xl font-bold">
              POLÍTICA DE COOKIES
            </h1>
            <div className="py-4 text-sm">
              <h2 className="mt-6 mb-2 font-bold">¿QUÉ SON LAS COOKIES?</h2>
              <p>
                Este sitio web utiliza cookies y/o tecnologías similares que
                almacenan y recuperan información cuando navegas. En general,
                estas tecnologías pueden servir para finalidades muy diversas,
                como, por ejemplo, reconocerte como usuario/a, obtener
                información sobre tus hábitos de navegación, o personalizar la
                forma en que se muestra el contenido. Los usos concretos que
                hacemos de estas tecnologías se describen a continuación.
              </p>
              <h2 className="mt-6 mb-2 font-bold">
                ¿QUÉ TIPOS DE COOKIES SE UTILIZAN EN ESTA PÁGINA WEB?
              </h2>
              <p>
                a) Cookies técnicas: son aquellas que permiten al usuario/a la
                navegación a través de la página web, plataforma o aplicación y
                la utilización de las diferentes opciones o servicios que en
                ella existen, incluyendo aquellas que se utilizan para permitir
                la gestión y operativa de la página web y habilitar sus
                funciones y servicios, como, por ejemplo, controlar el tráfico y
                la comunicación de datos, identificar la sesión, acceder a
                partes de acceso restringido, etc.
                <br />
                b) Cookies de análisis o medición: son aquellas que, tratadas
                por nosotros o por terceros, nos permiten cuantificar el número
                de usuarios/as y así realizar la medición y análisis estadístico
                de la utilización que hacen los/las usuarios/as del servicio
                ofertado. Para ello se analiza su navegación en nuestra página
                web con el fin de mejorar la oferta de productos o servicios que
                le ofrecemos.
              </p>
              <h2 className="mt-6 mb-2 font-bold">
                ¿QUIÉN UTILIZA LAS COOKIES?
              </h2>
              <p>
                a) Cookies propias: son aquellas que se envían al equipo
                terminal del usuario/a desde un equipo o dominio gestionado por
                el propio responsable de la web y desde el que se presta el
                servicio solicitado por el/la usuario/a.
                <br />
                b) Cookies de tercero: son aquellas que se envían al equipo
                terminal del usuario/a desde un equipo o dominio que no es
                gestionado por el responsable de la web, sino por otra entidad
                que trata los datos obtenidos través de las cookies.
                <br />
                <br />
                Esta página web utiliza Matomo Analytics, un servicio analítico
                de web prestado por Matomo, una compañía de Nueva Zelanda cuya
                oficina principal está en 150 Willis, Wellington, Wellington
                6011,NZ. Matomo Analytics utiliza “cookies” para ayudar al
                website a analizar el uso que hacen los usuarios del sitio web.
                La información que generan la cookies acerca de su uso del
                website será directamente archivada en nuestros propios
                servidores. Usaremos esta información con el propósito de seguir
                la pista de su uso del website y recopilando informes de la
                actividad del website. Si está de acuerdo con las cookies de
                Matomo, se almacenarán los siguientes datos:
              </p>
              <ul className="my-4 list-inside">
                <li className="list-disc">
                  Dirección IP acortada y nombre del dispositivo con el que
                  accede al website.
                </li>
                <li className="list-disc">
                  Nombre, idioma y versión de su navegador
                </li>
                <li className="list-disc">Sistema operativo</li>
                <li className="list-disc">La URL de la página visitada</li>
                <li className="list-disc">Fecha y hora de acceso</li>
              </ul>
              <p>
                Puede consultar el Uso de las cookies de Matomo Analytics en
                sitios web{" "}
                <a
                  href="https://matomo.org/faq/general/faq_146/"
                  className="text-cookiesBlue underline font-bold"
                >
                  AQUÍ
                </a>
              </p>
              <h2 className="mt-6 mb-2 font-bold">
                COOKIES UTILIZADAS EN ESTE SITIO WEB
              </h2>
              <p>
                A continuación se identifican las cookies que están siendo
                utilizadas en este portal así como su tipología y función:
              </p>
              <div className="bg-gray-100 text-black p-2 mt-4">
                <div>
                  <div className="py-1 flex">
                    <div className="w-1/3">
                      <strong>Cookie:</strong>
                    </div>
                    <div className="w-2/3">cookie_consent</div>
                  </div>
                  <div className="py1 flex">
                    <div className="w-1/3">
                      <strong>Duración:</strong>
                    </div>
                    <div className="w-2/3">1 año</div>
                  </div>
                  <div className="py-1 flex">
                    <div className="w-1/3">
                      <strong>Descripción:</strong>
                    </div>
                    <div className="w-2/3">
                      Esta cookie se utiliza para saber si el usuario ha
                      guardado su configuración de cookies y mostrarle el banner
                      de aceptación.
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="py-1 flex">
                    <div className="w-1/3">
                      <strong>Cookie:</strong>
                    </div>
                    <div className="w-2/3">_pk_id</div>
                  </div>
                  <div className="py1 flex">
                    <div className="w-1/3">
                      <strong>Duración:</strong>
                    </div>
                    <div className="w-2/3">13 meses</div>
                  </div>
                  <div className="py-1 flex">
                    <div className="w-1/3">
                      <strong>Descripción:</strong>
                    </div>
                    <div className="w-2/3">
                      Se utiliza para almacenar algunos detalles sobre el
                      usuario, como la identificación única del visitante.
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="py-1 flex">
                    <div className="w-1/3">
                      <strong>Cookie:</strong>
                    </div>
                    <div className="w-2/3">_pk_ses</div>
                  </div>
                  <div className="py1 flex">
                    <div className="w-1/3">
                      <strong>Duración:</strong>
                    </div>
                    <div className="w-2/3">30 minutos</div>
                  </div>
                  <div className="py-1 flex">
                    <div className="w-1/3">
                      <strong>Descripción:</strong>
                    </div>
                    <div className="w-2/3">
                      Se usa para almacenar temporalmente los datos de la
                      visita.
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="py-1 flex">
                    <div className="w-1/3">
                      <strong>Cookie:</strong>
                    </div>
                    <div className="w-2/3">analytics-imascono</div>
                  </div>
                  <div className="py1 flex">
                    <div className="w-1/3">
                      <strong>Duración:</strong>
                    </div>
                    <div className="w-2/3">1 año</div>
                  </div>
                  <div className="py-1 flex">
                    <div className="w-1/3">
                      <strong>Descripción:</strong>
                    </div>
                    <div className="w-2/3">
                      Se utiliza para recordar que el usuario ha dado su
                      consentimiento para el almacenamiento de los datos
                      registrados en las analíticas de Imascono.
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="mt-6 mb-2 font-bold">
                ¿CÓMO PUEDE ACEPTAR, DENEGAR, REVOCAR EL CONSENTIMIENTO O
                ELIMINAR LAS COOKIES?
              </h2>
              <p>
                Tienes la opción de permitir, bloquear o eliminar las cookies
                instaladas en tu equipo mediante la configuración de las
                opciones del navegador instalado en él. Al desactivar cookies,
                algunos de los servicios disponibles podrían dejar de estar
                operativos. La forma de deshabilitar las cookies es diferente
                para cada navegador, pero normalmente puede hacerse desde el
                menú Herramientas u Opciones. También puede consultarse el menú
                de Ayuda del navegador dónde puedes encontrar instrucciones. La
                persona usuaria podrá en cualquier momento elegir qué cookies
                quiere que funcionen en este sitio web.
                <br />
                Puedes permitir, bloquear o eliminar las cookies instaladas en
                tu equipo mediante la configuración de las opciones del
                navegador instalado en tu ordenador:
              </p>
              <ul className="mt-4 list-inside">
                <li className="list-disc">
                  <a
                    href="https://support.google.com/chrome/answer/95647?hl=es"
                    className="text-cookiesBlue underline font-bold"
                  >
                    Google Chrome
                  </a>
                </li>
                <li className="list-disc">
                  <a
                    className="text-cookiesBlue underline font-bold"
                    href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&amp;redirectlocale=es"
                  >
                    Mozilla Firefox
                  </a>
                </li>
                <li className="list-disc">
                  <a
                    href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                    className="text-cookiesBlue underline font-bold"
                  >
                    Internet Explorer
                  </a>
                </li>
                <li className="list-disc">
                  <a
                    href="https://support.apple.com/kb/ph5042?locale=en_US&amp;viewlocale=es_es"
                    className="text-cookiesBlue underline font-bold"
                  >
                    Safari
                  </a>
                </li>
                <li className="list-disc">
                  <a
                    href="https://help.opera.com/en/latest/web-preferences/#cookies"
                    className="text-cookiesBlue underline font-bold"
                  >
                    Opera
                  </a>
                </li>
                <li className="list-disc">
                  <a
                    href="https://support.microsoft.com/en-us/windows/windows-phone-7-3ebc303c-59c0-d367-3995-f258b184fabb"
                    className="text-cookiesBlue underline font-bold"
                  >
                    Internet Explorer (Windows Phone)
                  </a>
                </li>
                <li className="list-disc">
                  <a
                    href="https://support.apple.com/en-us/HT201265"
                    className="text-cookiesBlue underline font-bold"
                  >
                    Safari IOS (iPhone y iPad)
                  </a>
                </li>
                <li className="list-disc">
                  <a
                    href="https://support.google.com/chrome/answer/114662?hl=es&amp;visit_id=637997831359600642-1061789918&amp;rd=1"
                    className="text-cookiesBlue underline font-bold"
                  >
                    Chrome Android
                  </a>
                </li>
              </ul>
              <h2 className="mt-6 mb-2 font-bold">
                TRANSFERENCIAS DE DATOS A TERCEROS PAÍSES
              </h2>
              <p>
                Puedes informarte de las transferencias a terceros países que,
                en su caso, realizan los terceros identificados en esta política
                de cookies en sus correspondientes políticas (ver los enlaces
                facilitados en el apartado “Cookies de terceros”).
              </p>
              <h2 className="mt-6 mb-2 font-bold">ELABORACIÓN DE PERFILES</h2>
              <p>
                No se elaborará un perfil de usuario basado en la monitorización
                de tus actividades de navegación en nuestro Sitio Web. Y en
                consecuencia, no se tomarán decisiones automatizadas basadas en
                dicha navegación.
              </p>
              <h2 className="mt-6 mb-2">
                PERIODO DE CONSERVACIÓN DE LAS COOKIES
              </h2>
              <p>
                a Cookies de sesión: expiran automáticamente cuando el usuario
                termina la sesión y suelen emplearse para conservar información
                necesaria mientras se presta un servicio solicitado por el
                usuario en una sola ocasión
                <br />b Cookies persistentes: permanecen almacenadas en el
                terminal del usuario durante un periodo determinado (desde unos
                minutos a varios años).
              </p>
              <h2 className="mt-6 mb-2 font-bold">
                MÁS INFORMACIÓN SOBRE TRATAMIENTO DE DATOS
              </h2>
              <p className="mt-4">
                Visite nuestra{" "}
                <a
                  className="text-cookiesBlue underline font-bold"
                  href="https://metrovacesa.com/politica-privacidad"
                  target="_blank"
                >
                  Política de Privacidad
                </a>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesPolitic;
