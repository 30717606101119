import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import CookiesAdvancedOptions from "./CookiesAdvancedOptions";
import { useNavigate } from "react-router-dom";
import { ICookiesText, getCookiesText } from "./backends";

const CookiesBanner = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [advancedOptionsVisible, setAdvancedOptions] = useState<boolean>(false);
  const [activeCookie, setActiveCookie] = useState<boolean>(false);
  const [cookiesText, setCookiesText] = useState<ICookiesText>();
  const LENGTH_USER_ID = 23;

  let navigate = useNavigate();

  const generateUserId = (length: number) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const saveCookies = (saveCookie: boolean) => {
    if (saveCookie) {
      activateAllCookies();
    } else {
      desactivateAllCookies();
    }
  };

  const handleActiveCookie = (value: boolean) => {
    setActiveCookie(value);
  };

  const addMatomoScript = (userId: string) => {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
                    var _paq = window._paq = window._paq || [];
                    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
                    ${
                      Cookies.get("analytics-imascono") !== undefined
                        ? "_paq.push(['setConsentGiven']);"
                        : "_paq.push(['forgetConsentGiven']);"
                    }
                    _paq.push(['setDocumentTitle', "Espacio principal"]);
                    _paq.push(['setCustomUrl', "Espacio principal"]);
                    _paq.push(['setUserId', "${userId}"]);
                    _paq.push(['trackPageView']);
                    _paq.push(['enableLinkTracking']);
                    (function() {
                        var u="https://matomo.imascono.com/";
                        _paq.push(['setTrackerUrl', u+'matomo.php']);
                        _paq.push(['setSiteId', '122']);
                        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
                    })();`;
    document.head.appendChild(script);
  };

  const activateAllCookies = () => {
    let userId = generateUserId(LENGTH_USER_ID);
    Cookies.set("analytics-imascono", userId, { expires: 365 });
    Cookies.set("cookie-consent", userId);
    addMatomoScript(userId);
    setVisible(false);
  };

  const desactivateAllCookies = () => {
    let userId = generateUserId(LENGTH_USER_ID);
    Cookies.set("cookie-consent", userId);
    if (Cookies.get("analytics-imascono") !== undefined) {
      Cookies.remove("analytics-imascono", { path: "/" });
    }
    addMatomoScript(userId);
    setVisible(false);
  };

  useEffect(() => {
    if (Cookies.get("cookie-consent") !== undefined) {
      setVisible(false);
      addMatomoScript(Cookies.get("cookie-consent")!);
    } else {
      setVisible(true);
    }
  }, []);

  useEffect(() => {
    getCookiesText()
      .then((value: ICookiesText) => {
        setCookiesText(value);
      })
      .catch((value) => {
        setCookiesText(value);
      });
  }, []);

  return (
    <>
      <div
        className={` ${
          !visible ? "hidden" : ""
        } absolute top-0 bg-black bg-opacity-60 w-full min-h-screen flex items-end justify-end z-50 font-gatterSansRegular`}
      >
        <div className="w-full bg-blueMetrovacesa px-8 py-4 text-white text-sm z-50">
          <div>
            <h2 className="text-base font-bold mb-4">{cookiesText?.title}</h2>
            <p>
              {cookiesText?.description}
              <br />
              <span
                className="underline font-bold leading-8 cursor-pointer"
                onClick={() => {
                  navigate("/cookies");
                }}
              >
                {" "}
                Política de cookies
              </span>
            </p>
          </div>
          {advancedOptionsVisible && (
            <CookiesAdvancedOptions
              callback={handleActiveCookie}
              cookiesText={cookiesText}
            />
          )}
          <div className="flex md:justify-end pt-4 pb-16 md:pb-2">
            <div className="flex items-center">
              <button
                className="py-2 font-light underline text-xs flex items-center h-full"
                onClick={() => {
                  if (advancedOptionsVisible) {
                    saveCookies(activeCookie);
                  } else {
                    setAdvancedOptions(true);
                  }
                }}
              >
                {advancedOptionsVisible
                  ? "Guardar configuración"
                  : "Configuración avanzada"}
              </button>
            </div>
            <div className="ml-6">
              <button
                className="rounded px-6 py-2 bg-white text-blueMetrovacesa font-bold transition duration-200 transform hover:scale-110"
                onClick={desactivateAllCookies}
              >
                Rechazar todas
              </button>
            </div>
            <div className="ml-6">
              <button
                className="rounded px-6 py-2 bg-white text-blueMetrovacesa font-bold transition duration-200 transform hover:scale-110"
                onClick={activateAllCookies}
              >
                Aceptar todas
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiesBanner;
