import { Routes } from "react-router";
import {
  BrowserRouter,
  HashRouter,
  MemoryRouter,
  Route,
} from "react-router-dom";
import Home from "./Home";
import CookiesPolitic from "./CookiesPolitic";
import PrivactiyPolitic from "./PrivacityPolitic";
import { useEffect } from "react";

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cookies" element={<CookiesPolitic />} />
          <Route path="/privacity" element={<PrivactiyPolitic />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
